import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "pdiffAvg", "rpbAvg", "scoredQuestions", "unscoredQuestions" ]

  connect() {
    this.updateStats()
  }

  updateStats() {
    setTimeout(() => {
      // The update computation must be delayed (via setTimeout) to allow the row state to settle.
      const aqueductTable  = window.AqueductTable
      const rows           = aqueductTable.state.rows
      const rowsOfInterest = rows.filter(row => row.selected && row.scored)

      this.pdiffAvgTarget.innerHTML = this.computeAverageText(rowsOfInterest.map(row => row.item_difficulty), 4)
      this.rpbAvgTarget.innerHTML   = this.computeAverageText(rowsOfInterest.map(row => row.discrimination ), 2)

      this.scoredQuestionsTarget.textContent   = rowsOfInterest.length
      this.unscoredQuestionsTarget.textContent = rows.filter(row => !row.scored && row.selected).length
    },500)
  }

  computeAverageText(rawValues, precision) {
    const values = rawValues.filter(value => Number.isFinite(value))
    const sum    = values.reduce((val, sum) => sum + val, 0)
    const count  = values.length

    return count == 0 ? '&nbsp;' : (sum / count).toFixed(precision)
  }
}
